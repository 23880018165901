<template>
  <div class="newsletter-group-selector">
    <label for="groups">{{ label }}</label>
    <multiselect
        id="groups"
        :value="value"
        @input="$emit('input', $event)"
        :options="newsletterGroupsOptions"
        :multiple="true"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        label="name"
        track-by="name"
        placeholder="Bitte auswählen"
        selectedLabel="Ausgewählt"
    >
    </multiselect>
    <small class="form-text text-muted w-100 mb-3">{{ description }}</small>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";

export default {
  name: "NewsletterGroupSelector",
  props: {
    label: {
      type: String,
      default: 'Zielgruppe(n)'
    },
    value: {
      type: Array,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newsletterGroupsOptions: []
    }
  },
  mounted() {
    axios.get('/newsletter/group')
        .then(response => {
          this.newsletterGroupsOptions = response.data
        })
        .catch(err => console.error('Problem while fetching newsletter groups', err))
  }
}
</script>

<style scoped>

</style>
