<template>
  <div class="newsletter-generator">
    <h4>Anmeldeformular für den E-Mail Versand</h4>
    <p>Mit dem Anmeldeformular können sich Ihre Kunden über die Website für den Newsletter registrieren</p>
    <CRow>
      <CCol xs="12" xl="4">
        <CInput label="Formulartitel einfügen" v-model="newsletter.title" description="Der Titel der über dem Formular angzeigt wird."/>
        <NewsletterGroupSelector
            v-model="newsletter.groups"
            description="Hier können Sie Zielgruppen auswählen, zukünftige Abonnenten werden dieser Zielgruppe zugeordnet.
                Mit Zielgruppen können Sie gezielte Artikel verfassen und an die jeweiligen Intressengruppen senden.
                Wenn keine Gruppe ausgewählt ist, bekommen Abonnenten ohne Zielgruppe alle Artikel die Sie verfassen."
        />
        <CSelect
            label="Vorname"
            :value="newsletter.firstName"
            @update:value="newsletter.firstName = $event"
            required
            :options="selectOptions"
            description="Wenn Sie 'Erforderlich' auswählen, muss der Abonnent den Vornamen ausfüllen.
              Bei 'Optional' wird der Vorname nur gespeichert wenn angegeben.
              Bei 'Nicht anzeigen' wird dieses Feld nicht angezeigt"
        />
        <CSelect
            label="Nachname"
            :value="newsletter.lastName"
            @update:value="newsletter.lastName = $event"
            required
            :options="selectOptions"
            description="Wenn Sie 'Erforderlich' auswählen, muss der Abonnent den Vornamen ausfüllen.
              Bei 'Optional' wird der Nachname nur gespeichert wenn angegeben.
              Bei 'Nicht anzeigen' wird dieses Feld nicht angezeigt"
        />
        <ColorPicker label="Button: Farbe" v-model="newsletter.submitColor"/>
        <CInput label="Absenden-Button: Text" v-model="newsletter.submitText" description="Dieser Text wird für den Absende-Button verwendet"/>
        <CInput label="Nach der Eintragung" v-model="newsletter.thanks" description="Was soll nach dem Absenden des Formulares als 'Dankeschön' angezeigt werden?"/>
      </CCol>

      <CCol xs="12" xl="8">
        <label><strong>Vorschau</strong></label>
        <iframe class="newsletter-preview" ref="previewIframe"></iframe>
        <br>
        <label>Newsletter HTML-Code | <a href="#" @click.prevent="copyNewsletterCodeIntoClipboard" class="copy-text">Hier klicken um gesamten Code zu kopieren</a></label>
        <textarea ref="newsletterCode" v-model="templateCode" readonly>
        </textarea>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import commons from "@/commons";
import ColorPicker from "@/components/ColorPicker";
import axios from "axios";
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";

export default {
  name: "NewsletterGenerator",
  components: {NewsletterGroupSelector, ColorPicker},
  mounted() {
    setTimeout(() => {
      this.updatePreview()
    }, 200)
  },
  data () {
    return {
      selectOptions: [
        { value: 'required', label: 'Erforderlich' },
        { value: 'optional', label: 'Optional' },
        { value: 'disabled', label: 'Nicht anzeigen' }
      ],
      iframeApiBaseUrl: process.env.VUE_APP_IFRAME_URL,
      showPreviewAndCode: false,
      iframeKey: commons.getFromStorage('currentRealm').iframeKey,
      templateCode: '',
      newsletter: {
        title: 'Newsletter abonnieren',
        groups: [],
        firstName: 'required',
        lastName: 'required',
        thanks: 'Danke für das Abonnement!',
        submitText: 'Absenden',
        submitColor: '#5d9286'
      }
    }
  },
  watch: {
    newsletter: {
      handler() {
        this.updatePreview()
      },
      deep: true
    }
  },
  methods: {
    updatePreview() {
      setTimeout(() => {
        if(this.$refs.previewIframe) {
          const tmpl = this.generateTemplate()
          this.templateCode = tmpl
          this.$refs.previewIframe.contentWindow.document.body.innerHTML = ''
          this.$refs.previewIframe.contentWindow.document.write(tmpl)
        }
      }, 100)
    },
    copyNewsletterCodeIntoClipboard() {
      const copyTextarea = this.$refs.newsletterCode
      copyTextarea.focus()
      copyTextarea.select()
      try {
        const successful = document.execCommand('copy')
        if (successful) {
          this.$toaster.makeToast('Kopiert!', 'Code wurde in die Zwischenablage kopiert.')
        } else {
          this.$toaster.makeToast('Fehler!', 'Code konnte nicht in die Zwischenablage kopiert werden.')
        }
      } catch (err) {
        this.$toaster.makeToast('Fehler!', 'Code konnte nicht in die Zwischenablage kopiert werden!')
      }
    },
    generateTemplate() {
      let tmpl = '<!-- Beginn LeadMeUp Anmeldeformular -->'
      tmpl += `
<style>
    #cprtn{background:#fff;clear:left;font:14px Helvetica,Arial,sans-serif;font-size:.95rem}
    #cprtn > form{display:block;position:relative;text-align:left;padding:10px 0 10px 3%}
    #cprtn label{display:block;margin:8px 8px 0;width:94%}
    #cprtn input{margin:8px 8px 0;height:32px;width:94%;padding-left:10px}
    #cprtn input[type="checkbox"]{margin:8px 2px 0 8px;height:initial;width:initial;display:inline}
    .cprtn-input-group{margin-bottom:22px}
    .cprtn-required-notice{text-align:right;font-size:.75rem;margin:8px 8px 0;width:94%}
    .cprtn-submit{margin:8px 8px 0;background:${this.newsletter.submitColor};color:#fff;width:120px;height:40px;border:0;transition:all .3s;cursor:pointer}
    .cprtn-submit:hover{background:${this.shadeColor(this.newsletter.submitColor, -30)}}
    #cprtn span{color:red}
    #submit-notice{color:#307e3a;font-weight:700;display:none;margin:8px 8px 0}
    #error-message{color:#bb1010;font-weight:700;display:none;margin:8px 8px 0}
</style>
<div id="cprtn">
  <form id="cprtn-form" action="${this.iframeApiBaseUrl}/newsletter/subscribe/${this.iframeKey}" method="POST">
    <h2>${this.newsletter.title}</h2>
    <div class="cprtn-required-notice">
      <span>*</span> erforderlich
    </div>
    <div class="cprtn-input-group">
      <label for="cprtn-email">E-Mail <span>*</span></label>
      <input id="cprtn-email" name="email" type="email" required>
    </div>
    ${this.newsletter.firstName !== 'disabled' ?
          `<div class="cprtn-input-group">
      <label for="cprtn-firstname">Vorname${this.newsletter.firstName === 'required' ? '<span> *</span>' : ''}</label>
      <input id="cprtn-firstname" name="firstName" type="text" autocomplete="given-name"${this.newsletter.firstName === 'required' ? ' required' : ''}>
    </div>` : ''}
    ${this.newsletter.lastName !== 'disabled' ?
          `<div class="cprtn-input-group">
      <label for="cprtn-lastname">Nachname${this.newsletter.lastName === 'required' ? '<span> *</span>' : ''}</label>
      <input id="cprtn-lastname" name="lastName" type="text" autocomplete="family-name"${this.newsletter.lastName === 'required' ? ' required' : ''}>
    </div>` : ''}
    <div class="cprtn-input-group">
      <input id="cprtn-dsgvo" name="dsgvo" type="checkbox" required>
      <label style="display:inline;width:auto;margin:8px 8px 0 4px" for="cprtn-dsgvo">
        Ich bin damit einverstanden, dass mich ${this.$commons.getFromStorage('currentRealm').name } (telefonisch oder per E-Mail) kontaktiert  und mich über die weitere Marktentwicklung und attraktive Angebote informiert und dazu meine angegebenen Daten speichert. Die <a target="blank" href="${this.iframeApiBaseUrl}/dsgvo-privacy/${this.iframeKey}"><u>Datenschutzhinweise</u></a> habe ich dabei zur Kenntnis genommen.<span>*</span>
      </label>
    </div>`
      this.newsletter.groups.forEach(group => {
        tmpl += `<input type="hidden" name="groups" value="${group.id}">`
      })
      tmpl += `<div class="cprtn-input-group">
      <p id="submit-notice">${this.newsletter.thanks}</p>
      <p id="error-message"></p>
      <button type="submit" class="cprtn-submit">${this.newsletter.submitText}</button>
    </div>
  </form>
</div>`
      tmpl += `<script type="text/javascript" src="${process.env.VUE_APP_CDN_URL}/static/cprtn.min.js"></` + 'script>'
      tmpl += '<!-- Ende LeadMeUp Anmeldeformular -->'
      return tmpl
    },
    shadeColor(color, percent) {
      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;
      G = (G<255)?G:255;
      B = (B<255)?B:255;

      const RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
      const GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
      const BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

      return "#"+RR+GG+BB;
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-generator {
  .newsletter-preview {
    width: 100%;
    height: 480px;
    margin-bottom: 6px;
    border: 1px solid;
  }
  textarea {
    width: 100%;
    height: 240px;
    margin-bottom: 6px;
  }
  .copy-text {
    text-decoration: underline;
    color: #163ece;
    &:hover {
      color: #05b9ae;
    }
  }
}
</style>
